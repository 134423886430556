import React, { useState } from "react";

import { WorkContent } from "../WorkContent/WorkContent";
import { WorkIntro } from "../WorkIntro/WorkIntro";

interface Props {
  data: {
    imageTitle: string;
    description: string[];
    artworks: {
      image: string;
      title: string;
      dimensions: string;
      medium: string;
      date: string;
      type: string;
    }[];
  };
}

export const Work = (props: Props) => {
  const { data } = props;

  const [page, setPage] = useState(0);

  return page === 0 ? (
    <WorkIntro
      imageTitle={data.imageTitle}
      description={data.description}
      setPage={setPage}
    />
  ) : (
    <WorkContent
      artwork={data.artworks[page - 1]}
      hasNextPage={page < data.artworks.length}
      page={page}
      setPage={setPage}
    />
  );
};
